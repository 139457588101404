import React, { useEffect } from 'react';
import { string, shape } from 'prop-types';
import qs from 'query-string';
import { usePasswordResetTokenInfo } from '../util/api';
import Layout from '../layouts/Layout';
import MetaData from '../components/MetaData';
import ChangePasswordForm from '../components/ChangePasswordForm';
import PasswordResetExpired from '../components/PasswordResetExpired';
import PageLoader from '../components/loaders/PageLoader';
import { PASSWORD_RESET_TOKEN_STATES } from '../consts';

UpdatePassword.propTypes = {
	location: shape({
		search: string.isRequired
	}).isRequired
};

export default function UpdatePassword({ location: { search } }) {
	const { token, email } = qs.parse(search || '');
	const [
		{
			data: { status },
			loading = true
		},
		getTokenInfo
	] = usePasswordResetTokenInfo(token, email);

	useEffect(getTokenInfo, []);

	return (
		<Layout>
			<MetaData title="Change password" />
			{loading ? (
				<PageLoader />
			) : status === PASSWORD_RESET_TOKEN_STATES.valid ? (
				<ChangePasswordForm {...{ token, email }} />
			) : (
				<PasswordResetExpired />
			)}
		</Layout>
	);
}
