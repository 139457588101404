import React, { useEffect } from 'react';
import { string } from 'prop-types';
import useStyles from '../hooks/useStyles';
import useBatchState from '../hooks/useBatchState';
import FormHeading from './FormHeading';
import Button from './button/Button';
import PasswordField from './form/PasswordField';
import { ERROR_TYPE } from '../hooks/useValidation';
import { getQaKey as qa } from '../util/qa';
import { useUpdatePassword } from '../util/api';
import FormError from './form/FormError';
import { PASSWORD_REGEX, PASSWORD_MESSAGES, LOGIN_PATH } from '../consts';

const MESSAGE = {
	pageHeading: 'Change password',
	pageSubheading: 'Please enter and confirm your new password',
	newPassword: 'New password',
	confirmPassword: 'Confirm password',
	passwordMismatch: 'Passwords do not match, please check and try again',
	submitButton: 'Change password'
};

ChangePasswordForm.propTypes = {
	token: string,
	email: string
};

export default function ChangePasswordForm({ token, email }) {
	const classes = useStyles(styles);
	const [{ data, loading, status }, updatePassword] = useUpdatePassword();
	const [{ password1, password2, error, serverError, patternMismatch }, setState] = useBatchState({
		password1: '',
		password2: '',
		error: undefined,
		patternMismatch: false,
		serverError: undefined
	});

	useEffect(() => {
		const error = password1 && password2 && password1 !== password2 ? MESSAGE.passwordMismatch : undefined;
		setState({ error });
	}, [password1, password2, setState]);

	useEffect(() => {
		if (data === true) window.location = LOGIN_PATH;
	}, [data, setState, status]);

	const onValidityChange = (_, { patternMismatch }) => setState({ patternMismatch });

	const onChange = ({ target: { name, value } }) => {
		setState({
			[name]: value,
			serverError: undefined
		});
	};

	const onSubmit = e => {
		e.preventDefault();
		if (e.target.checkValidity() && password1 === password2) {
			setState({ loading: true });
			updatePassword({
				json: {
					token,
					email,
					password: password1
				}
			});
		}
	};

	return (
		<div css={classes.root}>
			<FormHeading
				heading={MESSAGE.pageHeading}
				subHeading={MESSAGE.pageSubheading}
				innerCss={{ heading: classes.heading }}
			/>
			<form noValidate onSubmit={onSubmit} css={classes.form}>
				<input type="hidden" value={email} autoComplete="username" />
				<div css={classes.inputs}>
					<PasswordField
						pattern={PASSWORD_REGEX}
						name="password1"
						label={MESSAGE.newPassword}
						value={password1}
						placeholder={PASSWORD_MESSAGES.placeholder}
						autoComplete="new-password"
						onValidityChange={onValidityChange}
						onChange={onChange}
						errorMessage={patternMismatch ? PASSWORD_MESSAGES.formatError : ''}
						required
						{...qa('input:password')}
					/>
					<PasswordField
						name="password2"
						label={MESSAGE.confirmPassword}
						value={password2}
						autoComplete="new-password"
						overrideValidation={error ? ERROR_TYPE.inValid : undefined}
						errorMessage={error}
						onChange={onChange}
						required
						{...qa('input:confirm-password')}
					/>
				</div>
				<FormError error={serverError} />
				<Button
					disabled={password1 !== password2 || patternMismatch}
					loading={loading}
					type="submit"
					css={classes.submit}
					className="full-width">
					{MESSAGE.submitButton}
				</Button>
			</form>
		</div>
	);
}

function styles({ css }) {
	return {
		root: css`
			width: 360px;
		`,
		heading: css`
			margin-bottom: 8px;
		`,
		form: css`
			text-align: left;
			width: 100%;
		`,
		inputs: css`
			& > :not(:last-child) {
				margin-bottom: 16px;
			}
		`,
		submit: css`
			margin-top: 32px;
		`
	};
}
