import React from 'react';
import { PATH } from '../consts';
import { Link } from 'gatsby';
import useStyles from '../hooks/useStyles';

const MESSAGE = {
	title: 'Reset password link expired',
	clickHere: 'Click here',
	linkContext: ' to create a new one'
};

export default function PasswordResetExpired() {
	const classes = useStyles(styles);
	return (
		<div css={classes.root}>
			<h1 css={classes.title} className="h2">
				{MESSAGE.title}
			</h1>
			<p css={classes.subTitle}>
				<Link to={PATH.forgotPassword}>{MESSAGE.clickHere}</Link>
				{MESSAGE.linkContext}
			</p>
		</div>
	);
}

function styles({ css, theme: { color } }) {
	return {
		root: css`
			text-align: center;
		`,
		title: css`
			margin-bottom: 16px;
		`,
		subTitle: css`
			color: ${color.neutral};
		`
	};
}
