import { keyframes } from '@emotion/core';
import { oneOf, string } from 'prop-types';
import React from 'react';
import useStyles from '../../hooks/useStyles';

DoubleBounce.propTypes = {
	className: string,
	variant: oneOf(['dark', 'light'])
};

DoubleBounce.defaultProps = {
	variant: 'light'
};

export default function DoubleBounce({ className, variant }) {
	const classes = useStyles(styles, { variant });
	return (
		<span className={className} css={[classes.spinner]}>
			<span css={classes.bounce} />
			<span css={[classes.bounce, classes.bounce2]} />
		</span>
	);
}

const styles = ({ css, theme, data: { variant } }) => {
	const scale = keyframes`
        0%, 100% {
            transform: scale(0.0);
        } 50% {
            transform: scale(1);
        }
    `;

	return {
		spinner: css`
			display: inline-block;
			width: 100%;
			height: 100%;
			position: relative;
		`,
		bounce: css`
			display: inline-block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			opacity: 0.6;
			position: absolute;
			top: 0;
			left: 0;
			animation: ${scale} 2s infinite ease-in-out;
			background-color: ${variant === 'dark' ? theme.color.neutral : theme.color.faint};
		`,
		bounce2: css`
			animation-delay: -1s;
		`
	};
};
