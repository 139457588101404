import React from 'react';
import { oneOf, string } from 'prop-types';
import DoubleBounce from './DoubleBounce';
import useStyles from '../../hooks/useStyles';

function PageLoader({ variant, className }) {
	const classes = useStyles(styles);
	return (
		<span css={classes.root}>
			<span className={className} css={classes.loader}>
				<DoubleBounce variant={variant} />
			</span>
		</span>
	);
}

PageLoader.propTypes = {
	variant: oneOf(['light', 'dark']),
	className: string
};

PageLoader.defaultProps = {
	variant: 'light'
};

const styles = ({ css }) => ({
	root: css`
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	`,
	loader: css`
		height: 100px;
		width: 100px;
		margin: auto;
	`
});

export default PageLoader;
